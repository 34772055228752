<template>
  <div>
    <h3>
      Park Detail<span v-if="location.longName">
        - {{ location.longName }}</span
      >
      <hr />
    </h3>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.parkDetailsForm"
    />
    <div v-if="!loading">
      <ValidationObserver ref="parkDetailsForm">
        <form @submit.prevent="onSubmit">
          <div class="accordion" role="tablist">
            <div class="card mb-1">
              <div class="card-header" v-b-toggle.park-information>
                <i
                  class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
                ></i>
                <i
                  class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
                ></i>
                <div class="card-title mb-0">Primary Park Information</div>
              </div>
              <b-collapse
                id="park-information"
                visible
                accordion="admin-park-details"
                role="tabpanel"
              >
                <PrimaryParkInformationForm
                  :location="location"
                  @location-update="updateLocation"
                  :viewOnly="boolView"
                />
              </b-collapse>
            </div>
            <div class="card mb-1">
              <div class="card-header" role="tab" v-b-toggle.park-details>
                <i
                  class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
                ></i>
                <i
                  class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
                ></i>
                <div class="card-title mb-0">Park Details</div>
              </div>
              <b-collapse
                id="park-details"
                accordion="admin-park-details"
                role="tabpanel"
              >
                <ParkDetailsForm
                  :location="location"
                  @location-update="updateLocation"
                  :viewOnly="boolView"
                />
              </b-collapse>
            </div>

            <div class="card mb-1">
              <div class="card-header" role="tab" v-b-toggle.booking-details>
                <i
                  class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
                ></i>
                <i
                  class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
                ></i>
                <div class="card-title mb-0">Booking Details</div>
              </div>
              <b-collapse
                id="booking-details"
                accordion="admin-park-details"
                role="tabpanel"
              >
                <BookingDetailsForm
                  :location="location"
                  @location-update="updateLocation"
                  :viewOnly="boolView"
                />
              </b-collapse>
            </div>

            <div class="card mb-1">
              <div
                class="card-header"
                role="tab"
                v-b-toggle.activities-amenities
              >
                <i
                  class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
                ></i>
                <i
                  class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
                ></i>
                <div class="card-title mb-0">
                  Activities and Amenities (POI)
                </div>
              </div>
              <b-collapse
                id="activities-amenities"
                accordion="admin-park-details"
                role="tabpanel"
              >
                <div class="card-body">
                  <PointsOfInterestForm
                    :location="location"
                    @location-update="updateLocation"
                    :viewOnly="boolView"
                  />
                </div>
              </b-collapse>
            </div>

            <div class="card mb-1">
              <div class="card-header" role="tab" v-b-toggle.featured-park>
                <i
                  class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
                ></i>
                <i
                  class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
                ></i>
                <div class="card-title mb-0">Featured Park</div>
              </div>
              <b-collapse
                id="featured-park"
                accordion="admin-park-details"
                role="tabpanel"
              >
                <div class="card-body">
                  <FeaturedParkForm
                    :location="location"
                    @location-update="updateLocation"
                    :viewOnly="boolView"
                  />
                </div>
              </b-collapse>
            </div>

            <div class="card mb-1">
              <div class="card-header" role="tab" v-b-toggle.park-images>
                <i
                  class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
                ></i>
                <i
                  class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
                ></i>
                <div class="card-title mb-0">Park Images</div>
              </div>
              <b-collapse
                id="park-images"
                accordion="admin-park-details"
                role="tabpanel"
              >
                <div class="card-body">
                  <AdminLocationImages
                    @featuredImageUpdated="featuredImageUpdated"
                    :tenantId="tenantId"
                    :locationId="location.id"
                    :featuredImageUrl="location.featuredImageUrl"
                    :viewOnly="boolView"
                    @deleted="imageDeleted"
                  />
                </div>
              </b-collapse>
            </div>
            <div class="card mb-1">
              <div class="card-header" role="tab" v-b-toggle.loops-campgrounds>
                <i
                  class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
                ></i>
                <i
                  class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
                ></i>
                <div class="card-title mb-0">
                  Associated Loops And Campgrounds
                </div>
              </div>
              <b-collapse id="loops-campgrounds" role="tabpanel">
                <div class="card-body">
                  <LoopCampgroundForm
                    :locationId="location.id"
                    :viewOnly="boolView"
                    :tenantId="tenantId"
                  />
                </div>
              </b-collapse>
            </div>
            <div class="card mb-1">
              <div class="card-header" role="tab" v-b-toggle.park-pricing>
                <i
                  class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
                ></i>
                <i
                  class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
                ></i>
                <div class="card-title mb-0">
                  Override Park Inventory Pricing
                </div>
              </div>
              <b-collapse
                id="park-pricing"
                accordion="admin-park-details"
                role="tabpanel"
              >
                <div class="card-body">
                  <OverridePricingForm
                    :viewOnly="boolView"
                    :tenantId="tenantId"
                    :locationId="location.id"
                  />
                </div>
              </b-collapse>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="mt-4">
                <div v-if="unsavedChanges" class="error mb-2">
                  You have unsaved changes.
                </div>
                <button
                  type="submit"
                  v-show="!boolView"
                  class="btn btn-lg btn-primary mr-2"
                >
                  Save
                </button>
                <button
                  type="button"
                  v-show="boolView"
                  class="btn btn-lg btn-primary mr-2"
                  @click="modify"
                >
                  Modify
                </button>
                <button
                  class="btn btn-cancel btn-lg mr-2"
                  @click="cancel"
                  type="button"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <p class="mt-2">
        <span style="color: red;">*</span> Indicates a required field
      </p>
    </div>
    <div v-else class="text-center">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import AdminLocationService from "@/services/admin/AdminLocationService.js";
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import PrimaryParkInformationForm from "@/components/admin/parkdetails/PrimaryParkInformationForm";
import ParkDetailsForm from "@/components/admin/parkdetails/ParkDetailsForm";
import BookingDetailsForm from "@/components/admin/parkdetails/BookingDetailsForm";
import AdminLocationImages from "@/components/admin/AdminLocationImages.vue";
import FeaturedParkForm from "@/components/admin/parkdetails/FeaturedParkForm";
import PointsOfInterestForm from "@/components/admin/parkdetails/PointsOfInterestForm";
import OverridePricingForm from "@/components/admin/parkdetails/OverridePricingForm";
import LoopCampgroundForm from "@/components/admin/parkdetails/LoopCampgroundForm";

export default {
  name: "AdminParkDetail",
  title: "Admin - Park Detail",
  props: {
    locationId: {
      type: String,
      default: ""
    },
    view: {
      type: String
    }
  },
  components: {
    ValidationObserver,
    FormErrorAlert,
    PrimaryParkInformationForm,
    ParkDetailsForm,
    BookingDetailsForm,
    AdminLocationImages,
    FeaturedParkForm,
    PointsOfInterestForm,
    OverridePricingForm,
    LoopCampgroundForm
  },
  data() {
    return {
      boolView: true,
      loading: false,
      location: {},
      unsavedChanges: false,
      errors: []
    };
  },
  methods: {
    async onSubmit() {
      this.$refs.parkDetailsForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.parkDetailsForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.parkDetailsForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            this.loading = true;
            this.location.phoneNumber = this.location.phoneNumber ?? null;
            this.location.faxNumber = this.location.faxNumber ?? null;
            this.location.locationPointOfInterests.forEach(
              x => (x.pointOfInterest = null)
            );
            const locationService = new AdminLocationService(this.tenantId);
            const lookupService = new AdminLookupService(this.tenantId);
            const loops = await lookupService.getLoopsForLocation(
              this.location.id
            );
            if (loops.length === 0 || loops === null) {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message:
                  "You must have at least one loop associated with this park...",
                layer: "admin"
              });
              window.scrollTo(0, 0);
              return;
            }
            const response = await locationService.saveLocation(this.location);
            if (response?.statusCode === "Success") {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-success",
                message: "Location saved.",
                layer: "admin"
              });
              window.scrollTo(0, 0);
              this.unsavedChanges = false;
            } else {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Something went wrong...",
                layer: "admin"
              });
              window.scrollTo(0, 0);
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          } finally {
            this.loading = false;
          }
        }
      });
    },
    async getLocation() {
      this.boolView = this.view === "true";
      this.loading = true;
      const locationService = new AdminLocationService(this.tenantId);
      const location = await locationService.getLocation(this.locationId);
      const pointsOfInterest = await locationService.getLocationPointsOfInterest(
        this.locationId
      );
      await Promise.allSettled([location, pointsOfInterest]).then(responses => {
        if (
          responses[0].value != null &&
          responses[1].value.statusCode === "Success"
        ) {
          this.location = responses[0].value;
          this.location.locationPointOfInterests = responses[1].value.data;
          this.location.region = null;
          this.location.state = this.location.state.trim();
          this.loading = false;
        } else {
          this.loading = false;
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      });
    },
    updateLocation(e) {
      const updatedLocation = { ...this.location, ...e };
      this.unsavedChanges =
        JSON.stringify(this.location) !== JSON.stringify(updatedLocation);
      this.location = updatedLocation;
    },
    cancel() {
      this.$router.push(`/admin/park-search`).catch(() => {});
    },
    modify() {
      this.boolView = false;
    },
    featuredImageUpdated(featuredUrl) {
      this.location.featuredImageUrl = featuredUrl;
      this.location = { ...this.location };
      this.onSubmit();
    },
    imageDeleted(id) {
      this.location.images = this.location.images.filter(n => n.id != id);
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  created() {
    this.getLocation();
  }
};
</script>

<style scoped>
.card-header {
  cursor: pointer;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>

<template>
  <div>
    <div class="text-center" v-if="loading">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
    <div v-else>
      <div v-if="!viewForm">
        <button
          type="button"
          class="btn btn-primary"
          @click="addNewSeason"
          :disabled="viewOnly"
        >
          ADD PRICING SEASON OVERRIDE
        </button>
        <b-table
          ref="table"
          striped
          :fields="seasonfields"
          :items="tableSeasons"
          show-empty
          empty-text="No Pricing Season Overrides..."
          stacked="md"
          small
          class="mt-4"
          bordered
          sort-icon-left
        >
          <template v-slot:cell(baseDailyRate)="data">
            {{ data.item.baseDailyRate | currency }}
          </template>
          <template v-slot:cell(startDate)="data">
            {{ data.item.startDate | formatDateAsUTCDate }}
          </template>
          <template v-slot:cell(endDate)="data">
            {{ data.item.endDate | formatDateAsUTCDate }}
          </template>
          <template v-slot:cell(id)="data">
            <button
              class="btn btn-primary btn-sm mx-1"
              @click="editSeason(data.item.id)"
              :disabled="viewOnly"
            >
              Modify
            </button>
          </template>
        </b-table>
      </div>
      <div v-else>
        <ValidationObserver ref="pricingSeasonOverrideForm">
          <FormErrorAlert
            v-if="errors.length > 0"
            :errors="errors"
            :formRef="this.$refs.pricingSeasonOverrideForm"
          />
          <form @submit.prevent="onSubmit">
            <div class="row">
              <div class="col-md-5 col-sm-12">
                <TextInput
                  disabled
                  name="Tenant"
                  id="tenantId"
                  v-model="tenantId"
                />
                <small class="form-text text-muted mb-2">Tenant Name</small>
              </div>
              <div class="col-md-5 col-sm-12">
                <TextInput
                  disabled
                  name="Fee Id"
                  id="feeReference"
                  v-model="season.feeReference"
                />
                <small class="form-text text-muted mb-2"
                  >Auto-generated ID for pricing season.</small
                >
              </div>
              <div class="col-md-5 col-sm-12">
                <ValidationProvider
                  rules="required"
                  name="Product Class"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label
                    @click="$refs.select.focus()"
                    :class="{ error: errors[0] }"
                    for="productClass"
                  >
                    Product Class
                    <span class="error">*</span>
                  </label>
                  <select
                    class="form-control form-control-lg"
                    id="productClass"
                    v-model="season.productClassificationId"
                    v-bind="ariaInput"
                  >
                    <option
                      v-for="item in productClasses"
                      :key="item.id"
                      :value="item.id"
                      >{{ item.name }}
                      {{ `- P${(item.id + "").padStart(3, "0")}` }}</option
                    >
                  </select>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
                <small class="form-text text-muted mb-2">
                  Select the Product Class applicable for pricing season. This
                  is single-select field.
                </small>
              </div>
              <div class="col-md-5 col-sm-12">
                <ValidationProvider
                  rules="required"
                  name="Active"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label
                    @click="$refs.select.focus()"
                    :class="{ error: errors[0] }"
                    for="active"
                  >
                    Active
                    <span class="error">*</span>
                  </label>
                  <select
                    class="form-control form-control-lg"
                    id="active"
                    v-model="season.isActive"
                    v-bind="ariaInput"
                  >
                    <option
                      v-for="(stat, index) in activeStatues"
                      :key="index"
                      :value="stat.value"
                      >{{ stat.name }}</option
                    >
                  </select>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
                <small class="form-text text-muted mb-2"
                  >Indicate the status of Pricing Season.</small
                >
              </div>
              <div class="col-md-5 col-sm-12">
                <DateInput
                  vid="arrivalDate"
                  rules="isDate|required"
                  name="Start Date"
                  id="startDate"
                  v-model="startDate"
                  :initialDate="initialStartDate"
                />
                <small class="form-text text-muted mb-2">
                  Date when the Pricing Override Starts. If 'Repeat Annually' is
                  checked, this override will persist each year starting with
                  this date.
                </small>
              </div>
              <div class="col-md-5 col-sm-12">
                <DateInput
                  vid="departureDate"
                  rules="isDate|required|departureDate:@arrivalDate"
                  name="End Date"
                  id="endDate"
                  v-model="endDate"
                  :dateDisabledFn="minDepartureDate"
                  :initialDate="initialEndDate"
                />
                <small class="form-text text-muted mb-2">
                  Date when the Pricing Override Ends. If 'Repeat Annually' is
                  checked, this override will persist each year ending with this
                  date.
                </small>
              </div>
              <div class="col-md-2 col-sm-12">
                <div class="d-flex align-items-center h-100">
                  <input
                    type="checkbox"
                    class="mr-2"
                    id="repeatAnnually"
                    v-model="season.repeatAnnually"
                  />
                  <label class="form-check-label" for="repeatAnnually"
                    >Repeat Annually</label
                  >
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <ValidationProvider
                  name="Fee Type"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label
                    @click="$refs.select.focus()"
                    :class="{ error: errors[0] }"
                    for="pricePointComponentTypeId"
                  >
                    Fee Type
                    <span class="error">*</span>
                  </label>
                  <select
                    class="form-control form-control-lg"
                    id="pricePointComponentTypeId"
                    v-model="pricePointComponentTypeId"
                    v-bind="ariaInput"
                    :disabled="!!season.id"
                    @change="setPricePointTableFields"
                  >
                    <option
                      v-for="(type, index) in pricePointComponentTypes"
                      :key="index"
                      :value="type.id"
                      >{{ type.name }}</option
                    >
                  </select>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
              </div>
              <div class="col-md-5 col-sm-12">
                <TextInput
                  rules="max:50"
                  name="Accounting Code"
                  id="accountingCode"
                  v-model="season.accountingCode"
                />
                <small class="form-text text-muted mb-2"
                  >Accounting Code to be used for the Pricing season.</small
                >
              </div>
            </div>

            <b-table
              ref="table"
              striped
              :fields="discountFields"
              :items="pricePoints"
              stacked="md"
              small
              class="mt-4"
              bordered
              sort-icon-left
            >
              <template
                v-for="(field, index) in discountFields"
                v-slot:[getTemplateKey(field.key)]="data"
              >
                <div :key="index">
                  <MoneyInput
                    v-model="data.item[field.key]"
                    :key="index"
                    :id="data.item.name + index"
                    v-if="
                      field.key !== 'name' &&
                        !data.item.notMoney &&
                        !data.item.hasBlankColumns
                    "
                    rules="between:0,999.99"
                    :name="data.item.name + ' ' + field.label"
                    :hideLabel="true"
                  />
                  <TextInput
                    :rules="`numeric|between:1,10000`"
                    :key="index"
                    :id="data.item.name + index"
                    v-model="data.item[field.key]"
                    v-if="field.key !== 'name' && data.item.notMoney"
                    :hideLabel="true"
                  />
                  <span v-if="field.key === 'name'">{{ data.item.name }}</span>
                </div>
              </template>
            </b-table>

            <button type="submit" class="btn btn-lg btn-primary mt-4 mr-2">
              Save
            </button>
            <button
              class="btn btn-lg btn-secondary mt-4 mr-2"
              @click="cancel"
              type="button"
            >
              Cancel
            </button>
            <p class="mt-2">
              <span style="color: red;">*</span> Indicates a required field
            </p>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import AdminPricingManagementService from "@/services/admin/AdminPricingManagementService.js";
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import AdminTenantService from "@/services/admin/AdminTenantService.js";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import DateInput from "@/validation/DateInput.vue";
import MoneyInput from "@/validation/MoneyInput.vue";
import TextInput from "@/validation/TextInput.vue";
import moment from "moment";
export default {
  name: "OverridePricingForm",
  props: {
    tenantId: String,
    locationId: Number,
    viewOnly: Boolean
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    FormErrorAlert,
    DateInput,
    MoneyInput,
    TextInput
  },
  data() {
    return {
      loading: false,
      viewForm: false,
      season: null,
      errors: [],
      seasons: [],
      productClasses: [],
      discountClasses: [],
      dateBlockDays: [],
      pricePoints: [],
      pricePointComponentTypes: [],
      pricePointComponentTypeId: 3,
      activeStatues: [
        { name: "Yes", value: true },
        { name: "No", value: false }
      ],
      seasonfields: [
        {
          key: "feeReference",
          sortable: true,
          label: "Fee Id"
        },
        { key: "isActive", sortable: true, label: "Is Active" },
        {
          key: "productClassification",
          sortable: true,
          label: "Product Class"
        },
        {
          key: "startDate",
          sortable: true
        },
        { key: "endDate", sortable: true },
        { key: "repeatAnnually", sortable: true },
        { key: "baseDailyRate", sortable: true },
        { key: "id", label: "Actions" }
      ]
    };
  },
  computed: {
    tableSeasons() {
      const tableSeasons = [];
      if (this.seasons) {
        this.seasons.forEach(x => {
          tableSeasons.push({
            id: x.id,
            feeReference: "FEE - " + x.id,
            isActive: x.isActive ? "Yes" : "No",
            repeatAnnually: x.repeatAnnually ? "Yes" : "No",
            startDate: x.startDate,
            endDate: x.endDate,
            productClassification: this.getProductClassName(
              x.productClassificationId
            ),
            baseDailyRate: this.getBaseDailyRate(x.id)
          });
        });
      }
      return tableSeasons;
    },
    basePricePoint() {
      return this.season.pricePoints.find(
        x => typeof x.dateBlock === "undefined"
      );
    },
    startDate: {
      get() {
        if (!this.season.startDate) {
          return "";
        }
        var isoDateString = this.season.startDate.toISOString();
        const date = moment(isoDateString);
        return date.utc().format("MM/DD/YYYY");
      },
      set(val) {
        // if it matches MM/DD/YYYY set, else dont
        if (
          val &&
          val.match(
            /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/
          )
        ) {
          this.season.startDate = new Date(val);
        }
      }
    },
    endDate: {
      get() {
        if (!this.season.endDate) {
          return "";
        }
        var isoDateString = this.season.endDate.toISOString();
        const date = moment(isoDateString);
        return date.utc().format("MM/DD/YYYY");
      },
      set(val) {
        // if it matches MM/DD/YYYY set, else dont
        if (
          val &&
          val.match(
            /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/
          )
        ) {
          this.season.endDate = new Date(val);
        }
      }
    },
    initialStartDate() {
      if (this.startDate) {
        return moment(this.startDate, "MM-DD-YYYY").format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    initialEndDate() {
      if (this.endDate) {
        return moment(this.endDate, "MM-DD-YYYY").format("YYYY-MM-DD");
      } else if (this.startDate) {
        return moment(this.startDate, "MM-DD-YYYY")
          .add(1, "days")
          .format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    discountFields() {
      const fields = [
        {
          key: "name",
          sortable: true,
          headerTitle: "Day Discount",
          label: "Price Point"
        },
        { key: "baseAmount", sortable: true, label: "Base Rate" }
      ];
      this.discountClasses.forEach(x => {
        fields.push({
          key: "baseAmount" + x.id,
          sortable: true,
          label: x.name
        });
      });
      return fields;
    },
    perDayComponentType() {
      return this.pricePointComponentTypes.find(
        x => x.name === "Use fee - per use period"
      );
    },
    perGroupComponentType() {
      return this.pricePointComponentTypes.find(
        x => x.name === "Use Fee - per person per use period"
      );
    }
  },
  methods: {
    addNewSeason() {
      this.season = {
        accountingCode: "",
        startDate: null,
        endDate: null,
        isActive: true,
        tenantId: this.tenantId,
        pricePoints: [],
        productClassificationId: ""
      };
      this.setNewSeasonPricePointsTableFields();
      this.viewForm = true;
    },
    editSeason(id) {
      let season = this.seasons.find(x => x.id === id);
      season = JSON.parse(JSON.stringify(season));
      season.startDate = new Date(season.startDate);
      season.endDate = new Date(season.endDate);
      this.season = season;
      this.pricePointComponentTypeId =
        this.season.pricePoints.length > 0
          ? this.season.pricePoints[0].pricePointComponents[0]
              .pricePointComponentTypeId
          : 3;
      this.setPricePointTableFields();
      this.viewForm = true;
    },
    cancel() {
      this.viewForm = false;
      this.season = null;
      this.pricePoints = [];
      this.errors = [];
    },
    async onSubmit() {
      if (!this.season.name) {
        this.season.name = this.getProductClassName(
          this.season.productClassificationId
        );
      }
      this.setPricePoints();
      this.$refs.pricingSeasonOverrideForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(
              this.$refs.pricingSeasonOverrideForm.errors
            )
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.pricingSeasonOverrideForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            this.$store.commit("auth/setLoading", true);
            const service = new AdminPricingManagementService(this.tenantId);
            const season = {
              id: this.season.id,
              locationId: this.locationId,
              repeatAnnually: this.season.repeatAnnually,
              name: this.season.name,
              tenantId: this.tenantId,
              startDate: this.startDate,
              endDate: this.endDate,
              isActive: this.season.isActive,
              accountingCode: this.season.accountingCode,
              productClassificationId: this.season.productClassificationId,
              pricePoints: this.season.pricePoints.map(x => {
                return {
                  id: x.id,
                  tenantId: x.tenantId,
                  pricinSeasonId: x.pricingSeasonId,
                  dateBlockDayId: x.dateBlockDayId,
                  dayBlockId: x.dayBlockId,
                  discountClassId: x.discountClassId,
                  pricePointComponents: x.pricePointComponents.map(y => {
                    return {
                      id: y.id,
                      tenantId: y.tenantId,
                      name: y.name,
                      minAttendeesCharged: y.minAttendeesCharged,
                      amountPerAdditionalAttendee:
                        y.amountPerAdditionalAttendee,
                      baseAmountAttendees: y.baseAmountAttendees,
                      feeTypeId: y.feeTypeId,
                      pricePointComponentTypeId: y.pricePointComponentTypeId,
                      baseAmount: y.baseAmount
                    };
                  })
                };
              })
            };
            const response = await service.saveLocationPricingSeason(season);
            if (response?.statusCode === "Success") {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-success",
                message: "Pricing Season has been saved.",
                layer: "admin"
              });
              this.initialize();
              this.cancel();
            } else {
              this.loading = false;
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Something went wrong...",
                layer: "admin"
              });
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          } finally {
            this.$store.commit("auth/setLoading", false);
          }
        }
      });
    },
    setNewSeasonPricePointsTableFields() {
      this.pricePoints = [];
      if (this.pricePointComponentTypeId == this.perGroupComponentType.id) {
        let minRate = {
          name: "Min Rate",
          baseAmount: 0
        };
        this.discountClasses.forEach(x => {
          minRate["baseAmount" + x.id] = 0;
        });
        this.pricePoints.push(minRate);
        let peopleInBaseRate = {
          name: "People Included in Min Rate",
          baseAmount: 0,
          notMoney: true
        };
        this.discountClasses.forEach(x => {
          peopleInBaseRate["baseAmount" + x.id] = 0;
        });
        this.pricePoints.push(peopleInBaseRate);
        this.pricePoints.push({
          name: "Additional Fee Per Person",
          hasBlankColumns: true
        });
      }

      let pricePoint = { name: "Base Weekday (Mon-Thu)", baseAmount: 0 };
      this.discountClasses.forEach(x => {
        pricePoint["baseAmount" + x.id] = 0;
      });
      this.pricePoints.push(pricePoint);

      pricePoint = { name: "Base Weekend (Fri-Sun)", baseAmount: 0 };
      this.discountClasses.forEach(x => {
        pricePoint["baseAmount" + x.id] = 0;
      });
      this.pricePoints.push(pricePoint);

      this.dateBlockDays.forEach(d => {
        pricePoint = {
          name: d.name + " Weekday (Mon-Thu)",
          baseAmount: 0,
          dateBlockDayId: d.id
        };
        this.discountClasses.forEach(x => {
          pricePoint["baseAmount" + x.id] = 0;
        });
        this.pricePoints.push(pricePoint);
        pricePoint = {
          name: d.name + " Weekend (Fri-Sun)",
          baseAmount: 0,
          dateBlockDayId: d.id
        };
        this.discountClasses.forEach(x => {
          pricePoint["baseAmount" + x.id] = 0;
        });
        this.pricePoints.push(pricePoint);
      });
    },
    setPricePoints() {
      this.season.pricePoints = [];
      let basePricePoint = {
        tenantId: this.tenantId,
        pricingSeasonId: this.season.id,
        pricePointComponents: [
          {
            feeTypeId: 1,
            pricePointComponentTypeId: this.pricePointComponentTypeId,
            tenantId: this.tenantId,
            name: "Park Use Fee"
          }
        ]
      };
      this.pricePoints.forEach(pp => {
        Object.keys(pp).forEach(key => {
          if (!key.startsWith("baseAmount")) {
            return;
          }
          let pricePoint = null;
          if (pp.dataObjects) {
            pricePoint = pp.dataObjects.find(x => x.key === key)?.value;
          }

          let minRatePoint = this.pricePoints.find(p => p.name == "Min Rate");
          let includedPeoplePoint = this.pricePoints.find(p => p.notMoney);
          let discountClassId = key.replace("baseAmount", "");
          if (pricePoint) {
            pricePoint = this.setPricePointComponentBaseAmount({
              pricePoint,
              pp,
              key,
              discountClassId,
              minRatePoint,
              includedPeoplePoint
            });
          } else if (!pp.notMoney && pp.name !== "Min Rate") {
            pricePoint = JSON.parse(JSON.stringify(basePricePoint));
            pricePoint.dayBlockId = pp["name"].includes("Weekend") ? 1 : null;
            pricePoint.dateBlockDayId = pp["dateBlockDayId"];
            if (discountClassId !== "" && key !== "dateBlockDayId") {
              pricePoint.discountClassId = +discountClassId;
              pricePoint = this.setPricePointComponentBaseAmount({
                pricePoint,
                pp,
                key,
                discountClassId,
                minRatePoint,
                includedPeoplePoint
              });
            } else {
              pricePoint = this.setPricePointComponentBaseAmount({
                pricePoint,
                pp,
                key,
                discountClassId,
                minRatePoint,
                includedPeoplePoint
              });
            }
          }
          if (pricePoint) {
            this.season.pricePoints.push(pricePoint);
          }
        });
      });
    },
    setPricePointComponentBaseAmount(params) {
      if (this.pricePointComponentTypeId == this.perDayComponentType.id) {
        params.pricePoint.pricePointComponents[0].baseAmount =
          params.pp[params.key];
      } else if (
        this.pricePointComponentTypeId == this.perGroupComponentType.id
      ) {
        params.pricePoint.pricePointComponents[0].amountPerAdditionalAttendee =
          params.pp[params.key];
        params.pricePoint.pricePointComponents[0].baseAmount =
          params.minRatePoint["baseAmount" + params.discountClassId];
        params.pricePoint.pricePointComponents[0].baseAmountAttendees =
          params.includedPeoplePoint["baseAmount" + params.discountClassId];
      }
      return params.pricePoint;
    },
    setPricePointTableFields() {
      if (this.season.pricePoints.length === 0) {
        this.setNewSeasonPricePointsTableFields();
        return;
      }

      this.season.pricePoints.forEach(x => {
        let createdPricePoint = this.pricePoints.find(
          pp =>
            pp.dayBlockId === x.dayBlockId &&
            pp.dateBlockDayId === x.dateBlockDayId
        );
        let pricePoint = createdPricePoint
          ? createdPricePoint
          : {
              dataObjects: []
            };

        let discountClassId = x.discountClassId ? x.discountClassId : "";
        pricePoint["baseAmount" + discountClassId] =
          this.pricePointComponentTypeId == this.perGroupComponentType.id
            ? x.pricePointComponents[0].amountPerAdditionalAttendee
            : x.pricePointComponents[0].baseAmount;
        pricePoint.dataObjects.push({
          key: "baseAmount" + discountClassId,
          value: x
        });
        if (!createdPricePoint) {
          if (!x.dateBlockDayId) {
            pricePoint.name =
              "Base" +
              (x.dayBlockId ? " Weekend (Fri-Sun)" : " Weekday (Mon-Thu)");
          } else {
            const day = this.dateBlockDays.find(d => d.id === x.dateBlockDayId);
            pricePoint.name =
              day.name +
              (x.dayBlockId ? " Weekend (Fri-Sun)" : " Weekday (Mon-Thu)");
          }
          pricePoint.dayBlockId = x.dayBlockId;
          pricePoint.dateBlockDayId = x.dateBlockDayId;
          this.pricePoints.push(pricePoint);
        }
      });

      if (this.pricePointComponentTypeId == this.perGroupComponentType.id) {
        let minRate = {
          name: "Min Rate",
          baseAmount: this.season.pricePoints.filter(
            p => p.discountClassId == null
          )[0].pricePointComponents[0].baseAmount
        };
        this.discountClasses.forEach(x => {
          let pricePoints = this.season.pricePoints.filter(
            p => p.discountClassId == x.id
          );
          minRate["baseAmount" + x.id] =
            pricePoints[0].pricePointComponents[0].baseAmount;
        });
        this.pricePoints.push(minRate);
        let peopleInBaseRate = {
          name: "People Included in Min Rate",
          baseAmount: this.season.pricePoints.filter(
            p => p.discountClassId == null
          )[0].pricePointComponents[0].baseAmountAttendees,
          notMoney: true
        };
        this.discountClasses.forEach(x => {
          let pricePoints = this.season.pricePoints.filter(
            p => p.discountClassId == x.id
          );
          peopleInBaseRate["baseAmount" + x.id] =
            pricePoints[0].pricePointComponents[0].baseAmountAttendees;
        });
        this.pricePoints.push(peopleInBaseRate);
        this.pricePoints.push({
          name: "Additional Fee Per Person",
          hasBlankColumns: true
        });
      }

      this.sortPricePoints();
    },
    sortPricePoints() {
      this.pricePoints.sort((a, b) => {
        if (a.name == "Min Rate" && b.name !== "Min Rate") {
          return -1;
        }
        if (
          a.notMoney &&
          !b.notMoney &&
          a.name !== "Min Rate" &&
          b.name !== "Min Rate"
        ) {
          return -1;
        }
        if (
          a.hasBlankColumns &&
          !b.hasBlankColumns &&
          !a.notMoney &&
          !b.notMoney &&
          a.name !== "Min Rate" &&
          b.name !== "Min Rate"
        ) {
          return -1;
        }
        if (
          !a.dayBlockId &&
          b.dayBlockId &&
          !a.dateBlockDayId &&
          !b.dateBlockDayId
        ) {
          return -1;
        }
        if (
          !a.dayBlockId &&
          b.dayBlockId &&
          a.dateBlockDayId <= b.dateBlockDayId
        ) {
          return -1;
        }
        if (a.dateBlockDayId < b.dateBlockDayId) {
          return -1;
        }
        return 1;
      });
    },
    minDepartureDate(ymd) {
      if (this.startDate) {
        return moment(this.startDate, "MM-DD-YYYY").isSameOrAfter(ymd, "day");
      } else {
        return moment().isSameOrAfter(ymd, "day");
      }
    },
    getTemplateKey(key) {
      return `cell(${key})`;
    },
    async getPricingSeasonsForLocation() {
      var service = new AdminPricingManagementService(this.tenantId);
      return await service.getPricingSeasonsForLocation(this.locationId);
    },
    async getProductClassesForLocation() {
      var service = new AdminLookupService(this.tenantId);
      return await service.getProductClassificationsForLocation(
        this.locationId
      );
    },
    async getDiscountClasses() {
      const service = new AdminPricingManagementService(this.tenantId);
      return await service.getDiscountClasses();
    },
    async getDateBlockDays() {
      const service = new AdminTenantService(this.tenantId);
      return await service.getDateBlockDays();
    },
    async getPricePointComponentTypes() {
      const service = new AdminTenantService(this.tenantId);
      return await service.getPricePointComponentTypes();
    },
    getBaseDailyRate(id) {
      const season = this.seasons.find(x => x.id === id);
      const pricePoint = season.pricePoints.find(
        x => typeof x.dateBlock === "undefined"
      );
      return pricePoint?.pricePointComponents[0]?.baseAmount;
    },
    getProductClassName(id) {
      return this.productClasses.find(x => x.id === id)?.name;
    },
    async initialize() {
      this.loading = true;
      const seasons = this.getPricingSeasonsForLocation();
      const productClasses = this.getProductClassesForLocation();
      const discounts = this.getDiscountClasses();
      const days = this.getDateBlockDays();
      const pricePointComponentTypes = this.getPricePointComponentTypes();
      await Promise.allSettled([
        seasons,
        productClasses,
        discounts,
        days,
        pricePointComponentTypes
      ])
        .then(responses => {
          this.seasons = responses[0].value.data;
          this.productClasses = responses[1].value;
          this.discountClasses = responses[2].value;
          this.dateBlockDays = responses[3].value.data;
          this.pricePointComponentTypes = responses[4].value.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  created() {
    this.initialize();
  }
};
</script>

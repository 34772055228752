<template>
  <div>
    <div
      class="text-center m-3"
      v-if="loading || loopButtonLoading || areaButtonLoading"
    >
      <span
        class="spinner-border spinner-border-sm mx-auto"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <p class="mb-2"><b>Loops:</b></p>
          <div class="d-flex">
            <TextInput
              v-model="loop"
              name="Add New Loop"
              size="sm"
              class="flex-grow-1"
              id="newLoop"
              :disabled="viewOnly"
              rules="alphaNumSpaces|min:1|max:100"
            /><button
              class="btn btn-primary btn-sm ml-2 h-50 align-self-end"
              type="button"
              :disabled="viewOnly"
              @click="saveNewLoop"
            >
              SAVE
            </button>
            <button
              class="btn btn-primary btn-sm ml-2 h-50 align-self-end"
              type="button"
              :disabled="viewOnly"
              @click="clearLoop"
            >
              CLEAR
            </button>
          </div>
          <hr />
          <div class="my-2" v-for="(loop, index) in loops" :key="loop.id">
            {{ index + 1 }}. {{ loop.name }}
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <p class="mb-2"><b>Campgrounds:</b></p>
          <div class="d-flex">
            <TextInput
              v-model="campground"
              name="Add New Campground"
              size="sm"
              class="flex-grow-1"
              id="newCampground"
              :disabled="viewOnly"
              rules="alphaNumSpaces|min:1|max:100"
            /><button
              class="btn btn-primary btn-sm ml-2 h-50 align-self-end"
              type="button"
              :disabled="viewOnly"
              @click="saveNewArea"
            >
              SAVE
            </button>
            <button
              class="btn btn-primary btn-sm ml-2 h-50 align-self-end"
              type="button"
              :disabled="viewOnly"
              @click="clearCampground"
            >
              CLEAR
            </button>
          </div>
          <hr />
          <div
            class="my-2"
            v-for="(campground, index) in campgrounds"
            :key="campground.id"
          >
            {{ index + 1 }}. {{ campground.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import AdminLocationService from "@/services/admin/AdminLocationService.js";
import TextInput from "@/validation/TextInput.vue";

export default {
  name: "LoopCampgroundForm",
  props: {
    locationId: Number,
    tenantId: String,
    viewOnly: Boolean
  },
  components: {
    TextInput
  },
  data() {
    return {
      loop: "",
      campground: "",
      loops: [],
      campgrounds: [],
      loading: false,
      areaButtonLoading: false,
      loopButtonLoading: false,
      errors: []
    };
  },
  methods: {
    async saveNewLoop() {
      if (
        this.loops.find(x => x.name.toLowerCase() === this.loop.toLowerCase())
      ) {
        window.scrollTo(0, 0);
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Loop already exists...",
          layer: "admin"
        });
        return;
      }
      this.loopButtonLoading = true;
      const newLoop = {
        name: this.loop,
        locationId: this.locationId,
        tenantId: this.tenantId
      };
      const service = new AdminLocationService(this.tenantId);
      const response = await service.saveLoop(newLoop);
      if (response?.statusCode === "Success") {
        this.loops.push(response.data);
        this.loopButtonLoading = false;
      } else {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
      this.clearLoop();
    },
    clearLoop() {
      this.loop = "";
    },
    async saveNewArea() {
      if (
        this.campgrounds.find(
          x => x.name.toLowerCase() === this.campground.toLowerCase()
        )
      ) {
        window.scrollTo(0, 0);
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Campground already exists...",
          layer: "admin"
        });
        return;
      }
      this.areaButtonLoading = true;
      const newArea = {
        name: this.campground,
        locationId: this.locationId,
        tenantId: this.tenantId
      };
      const service = new AdminLocationService(this.tenantId);
      const response = await service.saveArea(newArea);
      if (response?.statusCode === "Success") {
        this.campgrounds.push(response.data);
        this.areaButtonLoading = false;
      } else {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
      this.clearCampground();
    },
    clearCampground() {
      this.campground = "";
    },
    async getLoops() {
      const service = new AdminLookupService(this.tenantId);
      return await service.getLoopsForLocation(this.locationId);
    },
    async getCampgrounds() {
      const service = new AdminLookupService(this.tenantId);
      return await service.getAreasForLocation(this.locationId);
    },
    async initialize() {
      this.loading = true;
      const loops = this.getLoops();
      const campgrounds = this.getCampgrounds();
      await Promise.allSettled([loops, campgrounds])
        .then(responses => {
          this.loops = responses[0].value;
          this.campgrounds = responses[1].value;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  created() {
    this.initialize();
  }
};
</script>

<style scoped>
i {
  cursor: pointer;
}
</style>
